import React, { useState, useEffect } from 'react'
import { HomeIcon, PencilIcon, BuildingLibraryIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { 
  Typography,
  Card,
  CardBody,
  Input,
  Slider,
  Button,
  Spinner,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel, 
  slider,
  Textarea
} from "@material-tailwind/react";

import { v4 as uuidv4 } from 'uuid';
import Connect from './Connect';
import Inscribe from './Inscribe';

import 'react-toastify/dist/ReactToastify.css';
import '../custom-toast.css';
import { isValidTaprootAddress } from '../util/inscribe-util';

export default function Indexer() {
  const [addresses, setAddresses] = useState([]);
  const [text, setText] = useState('');

  const handleChange = (e) => {
    setText(e.target.value);
  }

  const checkValidateAddress = () => {
    let datas = text.split("\n");
    let temp = [];
    for(let data of datas) {
      if (!isValidTaprootAddress(data)){
        temp.push(data);
      }
    }
    setAddresses(temp);
  }

  return (
    <div className="flex flex-col gap-5 md:p-10 p-5 bg-[#F0F0F0] min-h-[100vh] w-full">
      <Textarea className="h-[700px]" label="Message" value={text} onChange={handleChange} />
      <Button onClick={checkValidateAddress}>Check address</Button>
      {
        addresses.length > 0 ? 
        <div className="text-red-500 text-[20px]">
          {
            addresses.map((address, index) => {
              return <div key={index}>{address}</div>
            } )
          }
        </div>
        :
        <div className="text-[24px] font-bold">
          There is no invalid bitcoin address.
        </div>
      }
    </div>
  )
}
